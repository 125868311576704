import { Container, Row, Col } from 'reactstrap';
import type { NextPage } from 'next';
import { Layout, SeoMeta } from '../components/Layout';
import { Section } from '../components/Sections';
import { Button } from '../components/Buttons';

const ErrorPage: NextPage = () => {
  return (
    <Layout>
      <SeoMeta title="Error 404" desc="" />
      <Section hasTop>
        <Container>
          <h1 className="font--xxl font--primary">
            It seems like this page does not exist anymore
          </h1>
          <Button title="Back To Home" href="./" />
        </Container>
      </Section>
    </Layout>
  );
};

export default ErrorPage;
